@layer base {
  .title {
  margin: 0 0 var(--spacer-400);
}

@media (--medium-viewport) {
  .title {
    margin-bottom: var(--spacer-700);
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .title--small {
    margin-bottom: var(--spacer-600);
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}