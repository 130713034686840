@layer base {
  .column {
  margin-top: 0;
  margin-bottom: 0;
}

.actionsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: var(--spacer-300) 0;
  gap: var(--spacer-200);
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacer-200);
}

}