@layer base {
  .title {
  margin: var(--sk-space-24) 0 var(--sk-space-8);
}

.radio {
  margin-top: var(--sk-space-16);
}

.previewResume {
  margin-top: var(--sk-space-16);
}

.upload {
  margin-top: var(--sk-space-16);
}

}