@layer base {
  .main {
  display: flex;
  position: fixed;
  z-index: var(--z-index-website-menu);
  box-sizing: border-box;
  flex-direction: column;
  width: var(--jt-bo-drawer-width);
  /* using svh instead of vh (when available) to avoid the drawer being too long on mobile devices */
  height: calc(100vh - var(--notification-banner-height));
  height: calc(100svh - var(--notification-banner-height));
  padding: 0 var(--spacer-100);
  background-color: var(--color-grey--025);
  box-shadow: 0 0 4px var(--color-grey--100);
}

.main__collapsed {
  position: sticky;
  top: var(--notification-banner-height);
  width: var(--jt-bo-drawer-collapsed-width);
}

.main__loading {
  margin: 0;
}

.backdrop {
  position: fixed;
  /*
    Below the drawer but above the rest of the page.
    (It is below the drawer with the same z-index because it is before in order of appearance in the HTML.)
  */
  z-index: var(--z-index-website-menu);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: var(--color-black);
}

.spacer {
  width: var(--jt-bo-drawer-collapsed-width);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacer-400);
  padding: var(--spacer-200) var(--spacer-150);
  transition: none;
  border-bottom: thin solid var(--color-grey--100);
  text-decoration: none;
  gap: var(--spacer-150);
}

.headerLink,
.headerLink:hover {
  display: flex;
  gap: var(--spacer-150);
  align-items: center;
  text-decoration: none;
}

.header__collapsed {
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
}

.header:hover {
  text-decoration: none;
}

.title {
  flex: 1;
}

.collapse {
  padding: 0 !important;
  border: none;
  stroke: var(--color-grey--500);
}

.collapse:hover::before {
  background: none;
}

.logo {
  /* ensure the link keeps the same size as the image */
  display: block;
  width: 40px;
  height: 40px;
  border-radius: var(--radius-100);
  background-color: var(--color--grey-50);
}

.menu {
  display: flex;
  flex-direction: column;
  min-height: 0; /* it allows the flex container to shrink below the height of its content if necessary */
  border-bottom: thin solid var(--color-grey--100);
}

.menuItemsList {
  margin-top: 0;
  /* Override BO londinium CSS */
  margin-bottom: 0;
  /* Override BO londinium CSS */
  padding-left: 0;
  /*
  The scrollable area is only on the menu items list and not on the whole menu,
  because the overflow-y auto or scroll is not compatible with the presence of dropdowns.
  It would create horizontal scrolling that we can't hide without making the dropdowns unusable.
  In CSS, if you are using visible for either overflow-x or overflow-y and something other than visible for the other,
  the visible value is interpreted as auto.
   */
  overflow-y: auto;
  list-style-type: none;
}

.dropdowns {
  flex: 1;
}

.helpCenter {
  margin-top: var(--spacer-150);
  margin-bottom: var(--spacer-200);
}

@media (--medium-viewport) {
  .main {
    position: sticky;
    top: var(--notification-banner-height);
  }
}

}