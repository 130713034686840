@layer base {
  .carousel {
  box-sizing: border-box;
  width: fit-content;
  max-width: 100%;
  border: var(--border);
  border-radius: var(--radius-100);
}

.item {
  position: relative;
  width: max-content;
  padding: var(--spacer-200) var(--spacer-300);
  overflow: hidden;
  border: none;
  background: transparent;
  font-family: var(--fontFamily);
  text-align: inherit;
  cursor: pointer;
}

/* setting borders on the first and last items prevents the hover pseudo-element to overflow the card */
/* we can't set an overflow: hidden on the Carousel because it would hide the Carousel arrows */

.item__first {
  border-radius: var(--radius-100) 0 0 var(--radius-100);
}

.item__last {
  border-radius: 0 var(--radius-100) var(--radius-100) 0;
}

.item:hover::after,
.item__active::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 4px;
}

.item:hover::after {
  background: var(--color-grey--200);
}

.item__active::after,
.item__active:hover::after {
  background: var(--color-black);
}

.separator {
  flex-shrink: 0;
  width: 1px;
  margin: var(--spacer-200) 0;
  background-color: var(--color-grey--100);
}

.date {
  color: var(--color-grey--600);
}

}