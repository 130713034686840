@layer base {
  .link {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 var(--spacer-100);
  border-radius: var(--radius-100);
  font-size: var(--normalBody);
  text-decoration: none;
  white-space: nowrap;
  gap: var(--spacer-100);
}

.link___unselected {
  color: var(--color-black);
}

.link___selected {
  background-color: var(--color-black);
  color: var(--color-white) !important;
}

.link___collapsed {
  justify-content: center;
}

.link:hover {
  background-color: var(--color-grey--100);
  text-decoration: none;
}

.link___selected:hover {
  background-color: var(--color-grey--800);
}

.link:focus {
  box-sizing: border-box;
  padding: 0 calc(var(--spacer-100) - 1px);
  border: thin solid var(--color-black);
  outline: none;
}

.linkText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.linkText___hidden {
  display: none;
}

}