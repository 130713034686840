@layer base {
  .dropdown {
  width: 100%;
}

.triggerButton {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 var(--spacer-100);
  border-color: transparent;
  background-color: transparent;
  color: black;
  gap: var(--spacer-100);
  font-size: var(--normalBody);
  font-weight: var(--normal);
  text-align: left;
}

.triggerButton__selected {
  background-color: var(--color-black);
  color: var(--color-white);
}

.triggerButton:hover {
  background-color: var(--color-grey--100);
}

/* reset CSS from Dropdown's Button component */
.triggerButton:hover::before {
  background-color: transparent;
}

.triggerButton:focus {
  border-color: var(--color-black);
  background-color: transparent;
}

.triggerButton__selected:hover {
  background-color: var(--color-grey--800);
}

.triggerButton__selected:focus {
  background-color: var(--color-black);
}

.triggerButtonInitial {
  width: var(--spacer-300);
  height: var(--spacer-300);
  border-radius: var(--radius-900);
  background-color: var(--color-grey--100);
  color: var(--color-black) !important;
  font-size: 10px;
  text-align: center;
}

.triggerButton:not(.triggerButton__selected):hover .triggerButtonInitial {
  background-color: var(--color-grey--200);
}

.triggerButtonText {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.triggerButtonArrowWithTag {
  display: flex;
  align-items: center;
  gap: var(--spacer-25);
}

.triggerButtonText___hidden {
  display: none;
}

.dropdownUserMenuItemLanguage {
  display: flex;
  justify-content: space-between;
  border-bottom: thin solid var(--color-grey--100);
}

.dropdownUserMenuItemCurrentLocale {
  text-transform: uppercase;
}

.dropdownConsumptionMenuItem {
  display: flex;
  justify-content: space-between;
  gap: var(--spacer-200);
  pointer-events: none;
}

.dropdownConsumptionMenuItemLink {
  border-top: thin solid var(--color-grey--100);
}

.dropdownConsumptionMenuItemValue {
  font-weight: var(--semiBold);
}

.newTag {
  margin-left: var(--spacer-200);
}

}