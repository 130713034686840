@layer base {
  .option {
  display: block;
}

.option:not(:last-child) {
  margin-bottom: var(--spacer-200);
}

.radioGroup {
  margin-bottom: var(--spacer-150);
}

.field {
  margin-bottom: var(--spacer-400);
}

.title {
  margin-bottom: var(--spacer-200);
}

}