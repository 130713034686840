@layer base {
  .links {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 10px;
  /* Override BO londinium CSS */
  padding-left: 0;
  list-style-type: none;
  gap: var(--spacer-100);
  font-size: var(--smallBody);
}

}