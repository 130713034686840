@layer base {
  /* stylelint-disable max-line-length */

/**
 * UI-Kit uses react-select and this one uses the Emotion CSS-in-JS library to style its components.
 * CSS-in-JS libraries are not compatible with our @layer strategy of CSS loading.
 * That's why we have to use !important on nextjs context.
 */

.select [class*="jds-Select__control"] {
  border-radius: var(--radius-200) !important;
  cursor: pointer !important;
}

.select [class*="jds-Select__dropdown-indicator"] {
  padding-right: var(--spacer-200) !important;
  padding-left: calc(var(--spacer-150) / 2) !important;
  color: var(--color-black) !important;
}

.select [class*="jds-Select__option"] {
  padding: var(--spacer-150) var(--spacer-200) !important;
}

.select [class*="jds-GroupedCheckbox"] [class*="jds-Select__option"] {
  padding-left: calc(var(--spacer-200) * 2) !important;
}

.select [class*="jds-Select__option--is-selected"] {
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
}

.select [class*="jds-Select__option--is-selected"] [class*="jds-Option__TickBox"] {
  color: var(--color-white) !important;
}

.select [class*="jds-Select__option--is-focused"],
.select [class*="jds-Select__option:hover"],
.select [class*="jds-Select__option:focus"] {
  background-color: var(--color-grey--025) !important;
}

.select[class*="jds-Select--hasError"] :global(.jds-Select__control) {
  border-color: var(--color-error--500) !important;
}

.select [class*="jds-Select__input-container"] {
  margin: 0 !important;
}

.select [class*="jds-Select__multi-value"] {
  border-radius: var(--radius-200) !important;
  cursor: pointer !important;
}

.select [class*="jds-Select__placeholder"],
.select [class*="jds-Select__single-value"],
.select [class*="jds-Select__input"] {
  margin: 0 !important;
}

.select
  [class*="jds-Select__control--is-focused jds-Select__control--menu-is-open"]
  [class*="jds-Select__single-value"] {
  color: var(--color-grey--500) !important;
}

.select [class*="jds-Select__value-container"] {
  padding: 0 0 0 var(--spacer-200) !important;
}

.select [class*="jds-Select__value-container--is-multi"] {
  max-height: 82px !important;
  overflow: auto !important;
}

.select [class*="jds-Select__value-container--is-multi jds-Select__value-container--has-value"] {
  padding: var(--spacer-100) 0 var(--spacer-100) var(--spacer-200) !important;
}

.select [class*="jds-Select__multi-value__remove"] {
  background-color: inherit !important;
  color: inherit !important;
  cursor: pointer !important;
}

.select :global .jds-Select__menu {
  box-shadow: var(--default-shadow) !important;
}

.select [class*="jds-Select__menu-list"] {
  max-height: 264px !important;
  padding: 0 !important;
  border-bottom: 1px solid var(--color-grey--100) !important;
}

[data-theme="jobteaser-uishift"] .select :global .jds-Select__menu {
  border-radius: var(--radius-400) !important;
}

[data-theme="jobteaser-uishift"] .select :global .jds-Select__indicators {
  height: var(--Select-innerHeight);
}

[data-theme="jobteaser-uishift"] .select [class*="IndicatorContainer_jds-IndicatorContainer__tag"] {
  margin-right: var(--spacer-200);
  padding: 0 var(--spacer-50) 0 var(--spacer-100);
  background-color: var(--color-grey--100);
  color: var(--color-black);
}

[data-theme="jobteaser-uishift"] .select :global .jds-Select__multi-value {
  border-radius: var(--radius-200) !important;
  background-color: var(--color-black) !important;
  color: var(--color-white) !important;
}

[data-theme="jobteaser-uishift"] .select :global .jds-Select__multi-value__label {
  color: var(--color-white) !important;
}

@media (--medium-viewport) {
  [data-theme="jobteaser-uishift"]
    .select
    [class*="IndicatorContainer_jds-IndicatorContainer__tag"]
    > div[role="button"] {
    display: none;
  }

  [data-theme="jobteaser-uishift"] .select [class*="IndicatorContainer_jds-IndicatorContainer__tag"] {
    padding: 0 var(--spacer-100) 0 var(--spacer-100);
  }

  [data-theme="jobteaser-uishift"] .select [class*="IndicatorContainer_jds-IndicatorContainer__tag"]:hover {
    padding: 0 var(--spacer-50) 0 var(--spacer-150);
  }

  [data-theme="jobteaser-uishift"]
    .select
    [class*="IndicatorContainer_jds-IndicatorContainer__tag"]:hover
    > div[role="button"] {
    display: inherit;
  }
}

}