@layer base {
  .main {
  overflow-y: visible;
}

.selectField {
  /* Use z-index to display the select options list in front of the modal buttons */
  z-index: var(--z-above-ui-kit-buttons);
}

}