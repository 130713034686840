@layer base {
  .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacer-300);
  padding: var(--spacer-500);
  border: solid 1px var(--color-grey--100);
  background-color: var(--color-white);
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-200);
}

@media (--medium-viewport) {
  .container {
    padding: var(--spacer-1000);
  }

  .heading {
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}